import React from 'react';
import NavbarMenuLinks from './NavbarMenuLinks';

function NavbarLinks() {
  return (
    <nav className="navbar__links-menu">
      <NavbarMenuLinks />
    </nav>
  );
}
export default NavbarLinks;
