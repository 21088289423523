import React from 'react';

function FooterContent() {
  return (
    <div>
      <h3>© 2023 Metacrafter | Developed by Prathmesh Vhatkar. Personal Portfolio | All Rights Reserved</h3>
    </div>
  );
}

export default FooterContent;
